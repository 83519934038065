const colors = {
    primary: {
        main: '#042357',
        dark: '#02183c'
    },
    secondary: '#DEDC1E',
    success: {
        main: '#3E8811'
    },
    warning: {
        main: '#f57c00',
        600: '#c96b0b'
    },
    error: {
        main: '#ED002B'
    },
    black: '#000',
    white: '#fff',
    grey: {
        100: '#f6f6f6',
        200: '#bec6d3',
        250: '#d8d9d9',
        300: '#bdbdbd',
        400: '#676c75',
        500: '#626263',
    },
    blue: {
        100: '#E1EBFF',
        200: '#226BE7',
    },
    greyBlue: {
        200: '#f5f5f8',
    },
    primary600: '#D2D1DB',
    primary700: '#8B8EA3',
    primary800: '#59617D',
    primary900: '#042357',
};

export default colors;