export const getPwaMessageQuery = (language = 'de') => ({
    query: `
        query getPwaMessage {
          getPwaMessageListing(defaultLanguage: "${language}") {
            edges {
              node {
                id,
                messageType,
                client,
                title,
                text
              }
            }
          }
        }
    `,
});

export const getPwaAdBannerQuery = (language = 'de') => ({
    query: `
        query getPwaAdBanner {
          getPwaAdBannerListing(defaultLanguage: "${language}") {
            edges {
              node {
                id,
                client,
                link {
                  text
                  path
                },
                asset {
                  ... on asset {
                    fullpath
                  }
                }
              }
            }
          }
        }
    `,
});

export const getTeamMemberByEmailQuery = (language = 'de', email) => ({
    query: `
        query getTeamMemberByEmail {
          getTeamMemberListing(first: 1, defaultLanguage: "${language}", filter: "{\\"email\\": { \\"$like\\": \\"${email}\\" }}") {
            edges {
              node {
                image {
                  ... on asset {
                    fullpath
                  }
                },
                onlineConsulting {
                  path
                }
              }
            }
          }
        }
    `,
});
