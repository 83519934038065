import React from 'react';
import ReactDOM from 'react-dom/client';
import {ThemeProvider} from "@mui/material";
import theme from './theme/theme';
import {PublicClientApplication} from "@azure/msal-browser";
import {MsalProvider} from "@azure/msal-react";
import {msalConfig} from "./config/authConfig";
import './i18n';
import Api from "./api/Api";
import {init} from "./i18n";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import AppWrapper from './components/AppWrapper';
import './index.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
const msalInstance = new PublicClientApplication(msalConfig);

serviceWorkerRegistration.register();

Api.getTranslations().then((res) => {
    init(res);

    root.render(
        <MsalProvider instance={msalInstance}>
            <ThemeProvider theme={theme}>
                <AppWrapper/>
            </ThemeProvider>
        </MsalProvider>
    );
});