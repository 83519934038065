import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Grid, IconButton, Modal, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Box from '@mui/material/Box';
import FormSection from '../form/FormSection';
import FormRow from '../form/FormRow';
import {formRowSizes} from '../../config/form/formRowSizes';
import {formRowTypes} from '../../config/form/formRowTypes';
import {LoadingButton} from '@mui/lab';
import {FormProvider, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {sendFeedback} from '../../redux/actions/feedback';
import {detectBrowser} from '../../utils/pageFunctions';
import {resetFeedback} from '../../redux/slices/feedback';
import {routeLayoutReleaseDetail} from '../../config/routeItems';
import {useMatch} from 'react-router-dom';

function PwaFeedbackCollector() {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [rating, setRating] = useState(5);
    const {user, client} = useSelector((state) => state.azure);
    const dispatch = useDispatch();
    const {isLoading, success} = useSelector((state) => state.feedback);
    const methods = useForm({});
    const isCorrectionDetail = useMatch(routeLayoutReleaseDetail.routingUrl);

    const onSubmit = (form) => {
        const formValues = {
            apiKey: process.env.REACT_APP_PIMCORE_CUSTOMER_PORTAL_APIKEY,
            client: client,
            customerName: user?.companyName,
            customerEmail: user?.email,
            contactNumber: user?.contactNumber,
            salutation: t(`customerportal.salutation.${user?.salutation}`),
            firstname: user?.firstname,
            lastname: user?.lastname,
            rating: rating,
            positiveFeedback: form.positiveFeedback,
            negativeFeedback: form.negativeFeedback,
            pageUrl: window.location.href,
            browser: detectBrowser(),
        };
        const formData = new FormData();

        for (let key in formValues) {
            formData.append(key, formValues[key]);
        }

        dispatch(sendFeedback({
            formData,
        }));
    };

    useEffect(() => {
        if (!open) {
            dispatch(resetFeedback());
            setRating(5);
            methods.reset();
        }
    }, [open, dispatch, methods]);

    return (
        <>
            <Button onClick={() => setOpen(true)}
                    variant={'contained'}
                    sx={{
                        backgroundColor: 'primary',
                        position: 'fixed',
                        zIndex: 100,
                        bottom: {
                            xs: isCorrectionDetail ? 140 : 80,
                            md: 20,
                        },
                        right: 20,
                        width: 'auto !important',
                        fontSize: 13,
                    }}>
                {t('customerportal.feedback_modal.button_text')}
            </Button>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box sx={{
                    backgroundColor: 'common.white',
                    maxHeight: {
                        xs: '90%',
                        sm: '80%',
                    },
                    width: {
                        xs: '90%',
                        sm: '80%',
                        md: '50%',
                    },
                    padding: 2,
                    borderRadius: 1,
                    position: 'relative',
                    overflowY: 'auto',
                }}>
                    <IconButton color="primary"
                                onClick={() => setOpen(false)}
                                sx={{
                                    position: 'absolute',
                                    top: 20,
                                    right: 20,
                                }}>
                        <CloseIcon/>
                    </IconButton>
                    {success ?
                        <Box>
                            <Typography variant={'h2'}>
                                {t('customerportal.feedback_modal.success_title')}
                            </Typography>
                            <Typography variant={'body1'} sx={{mt: 0.5}}>
                                {t('customerportal.feedback_modal.success_text')}
                            </Typography>
                        </Box> :
                        <Box>
                            <Typography variant={'h2'}>
                                {t('customerportal.feedback_modal.title')}
                            </Typography>
                            <Typography variant={'body1'} sx={{mt: 0.5}}>
                                {t('customerportal.feedback_modal.text')}
                            </Typography>
                            <Box sx={{mt: 2}}>
                                <Typography variant={'h3'}>
                                    {t('customerportal.form.rating')}
                                </Typography>
                                <Box sx={{mt: 0.5}}>
                                    {[...Array(5).keys()].map((ratingNumber) => {
                                        return rating >= ratingNumber + 1 ? (
                                            <StarIcon key={ratingNumber} onClick={() => setRating(ratingNumber + 1)}
                                                      sx={{
                                                          width: {
                                                              xs: 50,
                                                              sm: 60,
                                                          },
                                                          height: {
                                                              xs: 50,
                                                              sm: 60,
                                                          },
                                                          color: 'primary',
                                                          cursor: 'pointer',
                                                      }}/>
                                        ) : (
                                            <StarBorderIcon key={ratingNumber} onClick={() => setRating(ratingNumber + 1)}
                                                            sx={{
                                                                width: {
                                                                    xs: 50,
                                                                    sm: 60,
                                                                },
                                                                height: {
                                                                    xs: 50,
                                                                    sm: 60,
                                                                },
                                                                color: 'primary',
                                                                cursor: 'pointer',
                                                            }}/>
                                        );
                                    })}
                                </Box>
                            </Box>
                            <Box sx={{mt: 1}}>
                                <FormProvider {...methods}>
                                    <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                                        <FormSection>
                                            <FormRow field={{
                                                rowSize: formRowSizes.full,
                                                label: 'customerportal.form.positive_feedback',
                                                multiline: true,
                                                disabled: isLoading,
                                                id: 'positiveFeedback',
                                                type: formRowTypes.text,
                                            }}/>
                                            <FormRow field={{
                                                rowSize: formRowSizes.full,
                                                label: 'customerportal.form.negative_feedback',
                                                multiline: true,
                                                disabled: isLoading,
                                                id: 'negativeFeedback',
                                                type: formRowTypes.text,
                                            }}/>
                                            <Grid item xs={12}>
                                                <LoadingButton variant={'contained'}
                                                               loading={isLoading}
                                                               disableElevation={true}
                                                               color={'secondary'}
                                                               type={'submit'}
                                                >
                                                    <Typography>{t('customerportal.form.send_feedback')}</Typography>
                                                </LoadingButton>
                                            </Grid>
                                        </FormSection>
                                    </form>
                                </FormProvider>
                            </Box>
                        </Box>
                    }
                </Box>
            </Modal>
        </>
    );
}

export default PwaFeedbackCollector;