import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import {Link, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import {ArrowBackIosNewOutlined, KeyboardBackspaceOutlined} from '@mui/icons-material';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import SwitchClient from '../ui/SwitchClient';
import {fallbackLng, supportedLngs} from '../../i18n';
import SwitchLanguage from "../ui/SwitchLanguage";

function PageWrapper(props) {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    let {locale} = useParams();

    useEffect(() => {
        if (locale) {
            if (!supportedLngs.includes(locale)) {
                navigate(`/${fallbackLng}/dashboard`);
            }
        }
    }, [locale, navigate]);

    return (
        <Box sx={{
            flexGrow: 1,
            px: {
                xs: 0,
                md: 3.5,
            },
            py: {
                xs: 0,
                md: 5,
            },
            position: 'relative',
        }}>
            {props.languageSwitch && (
                <SwitchLanguage />
            )}
            {props.switchClient &&
                <SwitchClient/>
            }
            <Box sx={{
                textAlign: {
                    xs: 'center',
                    md: 'left',
                },
                px: {
                    xs: 2.5,
                    md: 0,
                },
                py: {
                    xs: 1,
                    md: 0,
                },
                mb: {
                    md: props.desktopSiteTitleDisabled && !props.backRoute ? 0 : 2,
                },
                borderBottom: {
                    xs: '1px solid',
                    md: 0,
                },
                borderColor: {
                    xs: 'grey.300',
                },
                position: 'relative',
                display: {
                    xs: props.mobileSiteTitleDisabled ? 'none' : 'block',
                    md: 'block',
                },
            }}>
                {props.backRoute &&
                    <Link component={RouterLink}
                          to={`/${i18n.language}${props.backRoute.url}`}
                          underline="hover"
                          sx={{
                              position: 'absolute',
                              display: 'flex',
                              alignItems: 'center',
                              height: '100%',
                              top: {
                                  xs: 0,
                                  md: '-100%',
                              },
                              left: {
                                  xs: 20,
                                  md: 0,
                              },
                          }}>
                        <ArrowBackIosNewOutlined sx={{
                            width: 20,
                            height: 20,
                            color: 'primary.main',
                            display: {
                                xs: 'block',
                                md: 'none',
                            },
                        }}/>
                        <KeyboardBackspaceOutlined sx={{
                            width: 20,
                            height: 20,
                            marginRight: 0.5,
                            color: 'primary.main',
                            display: {
                                xs: 'none',
                                md: 'block',
                            },
                        }}/>
                        <Typography sx={{
                            display: {
                                xs: 'none',
                                md: 'block',
                            },
                        }}>
                            {`${t('customerportal.navigation.back')} "${t(props.backRoute.label)}"`}
                        </Typography>
                    </Link>
                }
                <Typography sx={{
                    typography: {
                        xs: 'h3',
                        md: 'h1',
                    },
                    display: {
                        xs: props.mobileSiteTitleDisabled ? 'none' : 'block',
                        md: props.desktopSiteTitleDisabled ? 'none' : 'block',
                    },
                }} component={'h1'}>
                    {t(props.siteTitle)}
                </Typography>
            </Box>
            <Box sx={{
                flexGrow: 1,
                px: {
                    xs: 1.25,
                    md: 0,
                },
                paddingTop: {
                    xs: 2,
                    md: 0,
                },
                paddingBottom: {
                    xs: 6,
                    md: 0,
                },
                maxWidth: 2000
            }}>
                {props.children}
            </Box>
        </Box>
    );
}

PageWrapper.propTypes = {
    siteTitle: PropTypes.string.isRequired,
    desktopSiteTitleDisabled: PropTypes.bool,
    mobileSiteTitleDisabled: PropTypes.bool,
    languageSwitch: PropTypes.bool,
    switchClient: PropTypes.bool,
};

export default PageWrapper;