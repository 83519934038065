import React from 'react';
import {MsalAuthenticationTemplate, useMsal, useMsalAuthentication} from '@azure/msal-react';
import {InteractionStatus, InteractionType} from '@azure/msal-browser';
import {loginRequest} from '../config/authConfig';
import {Provider} from 'react-redux';
import {store} from '../redux/store';
import {CssBaseline} from '@mui/material';
import {BrowserRouter as Router} from 'react-router-dom';
import App from './App';
import {useTranslation} from 'react-i18next';

function AppWrapper() {
    const {instance, inProgress} = useMsal();
    const {i18n} = useTranslation();
    const {error} = useMsalAuthentication(
        InteractionType.Redirect,
    );

    if (error) {
        if(inProgress === InteractionStatus.None) {
            instance.loginRedirect(loginRequest(i18n.language));
        }
    } else {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}
                                        authenticationRequest={loginRequest(navigator.language || i18n.language)}>
                <Provider store={store}>
                    <CssBaseline/>
                    <Router>
                        <App/>
                    </Router>
                </Provider>
            </MsalAuthenticationTemplate>
        );
    }
}

export default AppWrapper;