import axios from 'axios';
import {getPwaMessageQuery, getPwaAdBannerQuery, getTeamMemberByEmailQuery} from './queries';
import {InteractionRequiredAuthError} from '@azure/msal-browser';
import {accessTokenRequest} from '../config/authConfig';
import parseTranslations from '../utils/parseTranslations';
import {INQUIRY_TYPE_PRODUCT_SAMPLE, INQUIRY_TYPE_OFFER} from '../config/customerPartalApi';
import getBase64File from '../utils/getBase64File';
import {ORDER_TYPE_CLOSE, ORDER_TYPE_OPEN, QUOTE_TYPE_CLOSED, QUOTE_TYPE_OPEN} from '../config/orderConfig';

class Api {
    endpoints = {
        pimcoreGraphQLEndpoint: `${process.env.REACT_APP_PIMCORE_GRAPHQL_ENDPOINT}?apikey=${process.env.REACT_APP_PIMCORE_GRAPHQL_APIKEY}`,
        getTranslations: `${process.env.REACT_APP_PIMCORE_CUSTOMER_PORTAL_ENDPOINT}/translations`,
        getAllContactPersons: `${process.env.REACT_APP_AZURE_REST_API}/company/contact_persons?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getUser: `${process.env.REACT_APP_AZURE_REST_API}/me/data?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        updateUser: `${process.env.REACT_APP_AZURE_REST_API}/update/contact?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        addCompanyContact: `${process.env.REACT_APP_AZURE_REST_API}/post/contact?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getCompanyData: `${process.env.REACT_APP_AZURE_REST_API}/customer/Corporate?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getCustomerAddresses: `${process.env.REACT_APP_AZURE_REST_API}/customer/addresses?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getRecipientEmails: `${process.env.REACT_APP_AZURE_REST_API}/customer/recipient_mails?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getBookedCreditMemos: `${process.env.REACT_APP_AZURE_REST_API}/customer/documents/booked_credit_memos?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getBookedInvoices: `${process.env.REACT_APP_AZURE_REST_API}/customer/documents/booked_invoices?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getBookedShipments: `${process.env.REACT_APP_AZURE_REST_API}/customer/documents/booked_shipments?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getOpenSalesReceipts: `${process.env.REACT_APP_AZURE_REST_API}/customer/documents/open_sales_receipts?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getCorrectionDrawings: `${process.env.REACT_APP_AZURE_REST_API}/customer/correction_drawings?code=${process.env.REACT_APP_AZURE_REST_APIKEY}&$orderby=erledigtInternAm desc, faelligkeitesdatumExtern desc`,
        getCorrectionDrawingsDetail: `${process.env.REACT_APP_AZURE_REST_API}/customer/correction_drawings_details?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getCompanyContacts: `${process.env.REACT_APP_AZURE_REST_API}/company/contacts?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        deactivateCompanyContact: `${process.env.REACT_APP_AZURE_REST_API}/update/contact/freischalten/%contactNo%?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getProducts: `${process.env.REACT_APP_AZURE_REST_API}/customer/products?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getOrdersOpen: `${process.env.REACT_APP_AZURE_REST_API}/customer/orders_open?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getOrdersClosed: `${process.env.REACT_APP_AZURE_REST_API}/customer/orders_closed?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getQuotesOpen: `${process.env.REACT_APP_AZURE_REST_API}/customer/quotes_open?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getQuotesClosed: `${process.env.REACT_APP_AZURE_REST_API}/customer/quotes_closed?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getOrderDocuments: `${process.env.REACT_APP_AZURE_REST_API}/customer/order_documents?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getQuotesDocuments: `${process.env.REACT_APP_AZURE_REST_API}/customer/quotes_documents?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getOrderDeliveries: `${process.env.REACT_APP_AZURE_REST_API}/customer/order_tracking?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getPossibleSalutations: `${process.env.REACT_APP_AZURE_REST_API}/general/salutation?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getPossiblePositions: `${process.env.REACT_APP_AZURE_REST_API}/general/position?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        getFileContent: `${process.env.REACT_APP_AZURE_REST_API}/sharepoint/file_content?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        changeClient: `${process.env.REACT_APP_AZURE_REST_API}/me/company?code=${process.env.REACT_APP_AZURE_REST_APIKEY}`,
        sendInquiry: `${process.env.REACT_APP_PIMCORE_CUSTOMER_PORTAL_ENDPOINT}/inquiry`,
        sendFeedback: `${process.env.REACT_APP_PIMCORE_CUSTOMER_PORTAL_ENDPOINT}/feedback`,
        sendGetOffer: `${process.env.REACT_APP_PIMCORE_CUSTOMER_PORTAL_ENDPOINT}/get-offer`,
        sendGetLLE: `${process.env.REACT_APP_PIMCORE_CUSTOMER_PORTAL_ENDPOINT}/get-lle`,
        changeDeliveryDate: `${process.env.REACT_APP_PIMCORE_CUSTOMER_PORTAL_ENDPOINT}/change-delivery-date`,
        sendCorrectionDrawingDetail: `${process.env.REACT_APP_PIMCORE_CUSTOMER_PORTAL_ENDPOINT}/correction-drawing`,
    };

    constructor() {
        this.token = '';
        let api = axios.create({
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: false,
        });

        api.interceptors.request.use((config) => {
            config.params = config.params || {};
            return config;
        });

        api.interceptors.response.use((response) => {
            return Promise.resolve(response);

        }, (error) => {

            // check online-mode on error to reload page to show offline-page
            if (!navigator.onLine) {
                console.log('disconnected internet, reload page');
                window.location.reload();
            }

            return Promise.reject(error);
        });

        this.client = api;
    }

    async acquireToken(instance, account) {
        if (!this.token || account.idTokenClaims.exp - (Date.now() / 1000) < (60 * 15)) {
            await instance
            .acquireTokenSilent(accessTokenRequest(account))
            .then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.idToken;
                this.token = `Bearer ${accessToken}`;
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance
                    .acquireTokenPopup(accessTokenRequest(account))
                    .then((accessTokenResponse) => {
                        // Acquire token interactive success
                        let accessToken = accessTokenResponse.idToken;
                        this.token = `Bearer ${accessToken}`;
                    })
                    .catch((error) => {
                        // Acquire token interactive failure
                        console.log(error);
                    });
                }
            });
        } else {
            return true;
        }
    }

    getPwaMessage(language) {
        const query = getPwaMessageQuery(language);

        return this.client.post(this.endpoints.pimcoreGraphQLEndpoint, query).then((response) => {
            return response.data;
        });
    }

    getPwaAdBanner(language) {
        const query = getPwaAdBannerQuery(language);

        return this.client.post(this.endpoints.pimcoreGraphQLEndpoint, query).then((response) => {
            return response.data;
        });
    }

    getAllContactPersons(language) {
        return this.client.get(this.endpoints.getAllContactPersons, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getUser(language) {
        return this.client.get(this.endpoints.getUser, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    changeClient(company) {
        return this.client.post(this.endpoints.changeClient, null, {
            headers: {
                Authorization: this.token,
            },
            params: {
                company: company,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getCustomerAddresses(language) {
        return this.client.get(this.endpoints.getCustomerAddresses, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getRecipientEmails(language) {
        return this.client.get(this.endpoints.getRecipientEmails, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getBookedCreditMemos(language) {
        return this.client.get(this.endpoints.getBookedCreditMemos, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getBookedInvoices(language) {
        return this.client.get(this.endpoints.getBookedInvoices, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getBookedShipments(language) {
        return this.client.get(this.endpoints.getBookedShipments, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getOpenSalesReceipts(language) {
        return this.client.get(this.endpoints.getOpenSalesReceipts, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getCorrectionDrawingsCount(language) {
        return this.client.get(`${this.endpoints.getCorrectionDrawings}&$top=0&$count=true`, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getCorrectionDrawings() {
        return this.client.get(`${this.endpoints.getCorrectionDrawings}&$count=true`, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getCorrectionDrawingsQuery(q) {
        return this.client.get(`${this.endpoints.getCorrectionDrawings}&$count=true&$filter=artikelnummer eq '${q}'`, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getCorrectionDrawingsDetail(itemNumber, languageCode) {
        return this.client.get(`${this.endpoints.getCorrectionDrawingsDetail}&artikelnummer=${itemNumber}&sprachcode=${languageCode}`, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getCorrectionDrawingsPDF(documentUrl) {
        return fetch(`${this.endpoints.getFileContent}&DocumentURL=${documentUrl}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/octet-stream',
            },
        }).then((res) => res.arrayBuffer()).then((response) => {
            return getBase64File(response);
        });
    }

    getProducts() {
        return this.client.get(`${this.endpoints.getProducts}&$count=true`, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getProductDetails(articleNumber) {
        return this.client.get(`${this.endpoints.getCorrectionDrawingsDetail}&artikelnummer=${articleNumber}`, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }


    getCompanyContacts() {
        return this.client.get(`${this.endpoints.getCompanyContacts}`, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }


    deactivateCompanyContact(data, contactNo, client) {
        return this.client.post(
            this.endpoints.deactivateCompanyContact
            .replace('%contactNo%', contactNo),
            data,
            {
                headers: {
                    Authorization: this.token,
                },
            }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getTeamMemberImage(language, email) {
        const query = getTeamMemberByEmailQuery(language, email);

        return this.client.post(this.endpoints.pimcoreGraphQLEndpoint, query).then((response) => {
            return response.data;
        });
    }

    getTranslations() {
        const data = new FormData();
        data.append('apiKey', process.env.REACT_APP_PIMCORE_CUSTOMER_PORTAL_APIKEY);

        return this.client.post(`${this.endpoints.getTranslations}`, data, {
            'Content-Type': 'multipart/form-data',
        }).then((response) => {
            return parseTranslations(response.data);
        });
    }

    sendInquiry(inquiryType, formData) {
        if (![INQUIRY_TYPE_PRODUCT_SAMPLE, INQUIRY_TYPE_OFFER].includes(inquiryType)) {
            return {
                success: false,
                errorMessage: 'inquiry type not valid',
                data: null,
            };
        }

        const url = `${this.endpoints.sendInquiry}/${inquiryType}`;


        return this.client.post(url, formData).then((response) => {
            return response.data;
        });
    }

    sendFeedback(formData) {
        return this.client.post(this.endpoints.sendFeedback, formData).then((response) => {
            return response.data;
        });
    }

    sendGetOffer(formData) {
        return this.client.post(this.endpoints.sendGetOffer, formData).then((response) => {
            return response.data;
        });
    }

    sendGetLLE(formData) {
        return this.client.post(this.endpoints.sendGetLLE, formData).then((response) => {
            return response.data;
        });
    }

    changeDeliveryDate(formData) {
        return this.client.post(this.endpoints.changeDeliveryDate, formData).then((response) => {
            return response.data;
        });
    }

    sendCorrectionDrawingDetail(formData) {
        return this.client.post(this.endpoints.sendCorrectionDrawingDetail, formData, {
            'Content-Type': 'multipart/form-data',
        }).then((response) => {
            return response.data;
        });
    }

    getOrders(type) {
        let url = '';

        switch (type) {
            case QUOTE_TYPE_OPEN:
                url = this.endpoints.getQuotesOpen;
                break;
            case QUOTE_TYPE_CLOSED:
                url = this.endpoints.getQuotesClosed;
                break;
            case ORDER_TYPE_CLOSE:
                url = this.endpoints.getOrdersClosed;
                break;
            case ORDER_TYPE_OPEN:
            default:
                url = this.endpoints.getOrdersOpen;
        }

        return this.client.get(url, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getQuotesDocuments(orderNumber) {
        const url = `${this.endpoints.getQuotesDocuments}&quotenumber=${orderNumber}`;
        return this.client.get(url, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getOrderDocuments(orderNumber) {
        const url = `${this.endpoints.getOrderDocuments}&assignmentNo=${orderNumber}`;
        return this.client.get(url, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getOrderDeliveries(orderNumber) {
        const url = `${this.endpoints.getOrderDeliveries}&ordernumber=${orderNumber}`;
        return this.client.get(url, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    updateUser(formData) {
        const url = this.endpoints.updateUser;
        return this.client.post(url, formData, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            return err.response.data;
        });
    }

    addCompanyContact(formData) {
        const url = this.endpoints.addCompanyContact;
        return this.client.post(url, formData, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            return err.response.data;
        });
    }

    getPossibleSalutations() {
        const url = this.endpoints.getPossibleSalutations;
        return this.client.get(url, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getPossiblePositions() {
        const url = this.endpoints.getPossiblePositions;
        return this.client.get(url, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getCompanyData() {
        const url = this.endpoints.getCompanyData;
        return this.client.get(url, {
            headers: {
                Authorization: this.token,
            },
        }).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

}

export default new Api();
