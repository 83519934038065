import {createAsyncThunk} from "@reduxjs/toolkit";
import Api from "../../api/Api";

export const getPwaMessage = createAsyncThunk('pimcore/get-pwa-message', async (payload = false, {rejectWithValue}) => {
    try {
        const res = await Api.getPwaMessage(payload.language);

        if (res.errors) {
            return rejectWithValue({
                error: res.errors[0]
            }, res.errors);
        } else {
            let message = null;

            if (res.data.getPwaMessageListing.edges.length > 0) {
                res.data.getPwaMessageListing.edges.forEach((pwaMessage) => {
                    if (!message) {
                        if (pwaMessage.node.client === payload.client || pwaMessage.node.client === '*') {
                            message = pwaMessage.node;
                        }
                    }
                });
            }

            if (message) {
                return {
                    message: message
                };
            } else {
                return rejectWithValue({
                    error: {
                        message: 'No message defined'
                    }
                }, {});
            }
        }
    } catch (err) {
        return rejectWithValue({
            error: err
        }, err);
    }
});

export const getPwaAdBanner = createAsyncThunk('pimcore/get-pwa-ad-banner', async (payload = false, {rejectWithValue}) => {
    try {
        const res = await Api.getPwaAdBanner(payload.language);

        if (res.errors) {
            return rejectWithValue({
                error: res.errors[0]
            }, res.errors);
        } else {
            let banner = null;

            if (res.data.getPwaAdBannerListing.edges.length > 0) {
                res.data.getPwaAdBannerListing.edges.forEach((pwaBanner) => {
                    if (!banner) {
                        if (pwaBanner.node.client === payload.client || pwaBanner.node.client === '*') {
                            banner = pwaBanner.node;
                        }
                    }
                });
            }

            if (banner) {
                return {
                    banner: banner
                };
            } else {
                return rejectWithValue({
                    error: {
                        message: 'No message defined'
                    }
                }, {});
            }
        }
    } catch (err) {
        return rejectWithValue({
            error: err
        }, err);
    }
});