import FrutigerLTProBoldWoff from '../assets/fonts/31FBB4_0_0.woff';
import FrutigerLTProBoldTtf from '../assets/fonts/31FBB4_0_0.ttf';
import FrutigerLTProBoldEot from '../assets/fonts/31FBB4_0_0.eot';
import FrutigerLTProLightWoff from '../assets/fonts/31FBB4_1_0.woff';
import FrutigerLTProLightTtf from '../assets/fonts/31FBB4_1_0.ttf';
import FrutigerLTProLightEot from '../assets/fonts/31FBB4_1_0.eot';

const fonts = {
    frutigerLtProBold: {
        fontFamily: '"FrutigerLTPro-Bold"',
        fontStyle: 'normal',
        fontDisplay: 'swap',
        fontWeight: 400,
        src: `
            url(${FrutigerLTProBoldEot}?#iefix) format('embedded-opentype'),
            url(${FrutigerLTProBoldWoff}) format('woff'),
            url(${FrutigerLTProBoldTtf}) format('truetype')
        `
    },
    frutigerLtProLight: {
        fontFamily: '"FrutigerLTPro-Light"',
        fontStyle: 'normal',
        fontDisplay: 'swap',
        fontWeight: 400,
        src: `
            url(${FrutigerLTProLightEot}?#iefix) format('embedded-opentype'),
            url(${FrutigerLTProLightWoff}) format('woff'),
            url(${FrutigerLTProLightTtf}) format('truetype')
        `
    }
};

export default fonts;
