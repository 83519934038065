export default function parseTranslations(data) {
    const rawTranslations = data.data;
    if (rawTranslations.length > 0) {
        const languages = [...new Set(rawTranslations.map(item => item.language))];
        const translations = {};

        languages.forEach(l => {
           translations[l] = {translation: {}};
        });

        rawTranslations.forEach(t => {
            translations[t.language].translation[t.key] = t.text;
        });

        return translations;
    }

    return null;
}
