import {createAsyncThunk} from '@reduxjs/toolkit';
import Api from '../../api/Api';

export const sendFeedback = createAsyncThunk('pimcore-custom/send-feedback', async (payload = false, {rejectWithValue}) => {
    try {
        const res = await Api.sendFeedback(payload.formData);

        if (res.status !== 'success') {
            return rejectWithValue({
                errorMessage: res.message,
            });
        }
    } catch (err) {
        return rejectWithValue({
            errorMessage: err.response.data.message,
        }, {});
    }
});
