import {createAsyncThunk} from '@reduxjs/toolkit';
import Api from '../../api/Api';

export const sendGetLLEForm = createAsyncThunk('pimcore-custom/send-get-lle-form', async (payload = false, {rejectWithValue}) => {
    try {
        let primaryEmail = '';

        switch (payload.client) {
            case 'rathgeber-cz':
                primaryEmail = 'v.micinova@rathgeber.cz';
                break;
            case 'smart-tec':
                primaryEmail = 'm.schmid@smart-tec.com';
                break;
            case 'rathgeber-pl':
                primaryEmail = payload.supervisorMail;
                break;
            case 'rathgeber':
            default:
                primaryEmail = 'zoll@rathgeber-group.net';
                break;
        }

        const formData = new FormData();
        formData.append('apiKey', process.env.REACT_APP_PIMCORE_CUSTOMER_PORTAL_APIKEY);
        formData.append('language', payload.language);
        formData.append('client', payload.client);
        formData.append('customerEmail', payload.customerEmail);
        formData.append('customerName', payload.customerName);
        formData.append('salutation', payload.salutation);
        formData.append('firstname', payload.firstname);
        formData.append('lastname', payload.lastname);
        formData.append('supervisorEmail', !!process.env.REACT_APP_TEST_EMAILS ? 'OH-SMBOX-TEAM-ERP@rathgeber.eu' : payload.supervisorMail);
        formData.append('primaryEmail', primaryEmail);
        formData.append('formData', JSON.stringify(payload.form));

        const res = await Api.sendGetLLE(formData);

        if (res.status !== 'success') {
            return rejectWithValue({
                errorMessage: res.message,
            });
        } else {
            return true;
        }
    } catch (err) {
        return rejectWithValue({
            errorMessage: err.response.data.message,
        }, {});
    }
});
