import {createAsyncThunk} from '@reduxjs/toolkit';
import Api from '../../api/Api';


export const sendInquiry = createAsyncThunk('pimcore-custom/send-inquiry', async (payload = false, {rejectWithValue}) => {
    try {
        const supervisorEmail = payload.supervisor?.email ?? null;

        if(!supervisorEmail) {
            return rejectWithValue({
                errorMessage: 'no supervisor email',
            });
        }

        const formData = new FormData();
        formData.append('apiKey', process.env.REACT_APP_PIMCORE_CUSTOMER_PORTAL_APIKEY);
        formData.append('language', payload.language);
        formData.append('client', payload.client);
        formData.append('customerNumber', payload.customerNumber);
        formData.append('customerName', payload.customerName);
        formData.append('supervisorEmail', !!process.env.REACT_APP_TEST_EMAILS ? 'OH-SMBOX-TEAM-ERP@rathgeber.eu' : supervisorEmail);
        formData.append('formData', JSON.stringify(payload.form));

        if (payload.form.files) {
            Array.from(payload.form.files).forEach((file, i) => {
                if (file instanceof Blob) {
                    formData.append(`file_${i}`, file);
                }
            });
        }

        const res = await Api.sendInquiry(payload.inquiryType, formData);

        if (res.status !== 'success') {
            return rejectWithValue({
                errorMessage: res.message,
            });
        }

    } catch (err) {
        return rejectWithValue({
            errorMessage: err.response.data.message,
        }, {});
    }
});
