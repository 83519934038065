import { LogLevel } from "@azure/msal-browser";

export const b2cPolicies = {
    names: {
        signUpSignIn: process.env.REACT_APP_MSAL_SUSI_NAME
    },
    authorities: {
        signUpSignIn: {
            authority: process.env.REACT_APP_MSAL_SUSI_AUTHORITY,
        }
    },
    authorityDomain: process.env.REACT_APP_MSAL_SUSI_AUTHORITY
}


export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENTID, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Use a sign-up/sign-in user-flow as a default authority
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: "/", // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

export const loginRequest = (language) => ({
    scopes: ['openid'],
    responseType: 'code+id_token',
    extraQueryParameters: {
        lang: language
    }
});


export const accessTokenRequest = (account) => ({
    authority: process.env.REACT_APP_MSAL_SUSI_AUTHORITY,
    scopes: [process.env.REACT_APP_MSAL_USER_IMPERSONATION, 'openid'],
    account: account
});