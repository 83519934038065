import fonts from './fonts';
import {alpha} from '@mui/material';

const overrideTheme = (baseTheme) => {
    baseTheme.components = {
        MuiCssBaseline: {
            styleOverrides: `   
                @font-face {
                  font-family: ${fonts.frutigerLtProLight.fontFamily};
                  font-style: ${fonts.frutigerLtProLight.fontStyle};
                  font-display: ${fonts.frutigerLtProLight.fontDisplay};
                  font-weight: ${fonts.frutigerLtProLight.fontWeight};
                  src: ${fonts.frutigerLtProLight.src};
                }
                @font-face {
                  font-family: ${fonts.frutigerLtProBold.fontFamily};
                  font-style: ${fonts.frutigerLtProBold.fontStyle};
                  font-display: ${fonts.frutigerLtProBold.fontDisplay};
                  font-weight: ${fonts.frutigerLtProBold.fontWeight};
                  src: ${fonts.frutigerLtProBold.src};
                }
            `,
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    padding: '6px 30px',
                    borderRadius: 4,
                    width: 'auto',
                    boxShadow: baseTheme.shadows[1],
                    fontSize: baseTheme.typography.body1.fontSize,
                    [baseTheme.breakpoints.down('sm')]: {
                        width: '100%',
                    },
                    '&:hover': {
                        boxShadow: baseTheme.shadows[3],
                    },
                },
                outlined: {
                    padding: '6px 30px',
                    borderRadius: 4,
                    width: 'auto',
                    boxShadow: baseTheme.shadows[1],
                    fontSize: baseTheme.typography.body1.fontSize,
                    [baseTheme.breakpoints.down('sm')]: {
                        width: '100%',
                    },
                    '&:hover': {
                        boxShadow: baseTheme.shadows[3],
                    },
                },
            },
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    minWidth: 'auto',
                    '& .MuiBottomNavigationAction-label': {
                        fontSize: baseTheme.typography.bottomNav.fontSize,
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '100%',
                    borderRadius: '4px 4px 0 0',
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: ({ownerState}) => ({
                    fontWeight: 'bold',
                    backgroundColor: baseTheme.palette.common.white,
                    ...(ownerState.disabled && {
                        '&.MuiFilledInput-root': {
                            backgroundColor: baseTheme.palette.common.white,
                            '&:before': {
                                borderBottomStyle: 'solid',
                            },
                        },
                    }),
                }),
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    borderRadius: '4px 4px 0 0',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    zIndex: 1,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: ({ownerState}) => ({
                    backgroundColor: baseTheme.palette.common.white,
                    fontWeight: 'bold',
                    marginTop: 0,
                    '&.Mui-disabled': {
                        '&:before': {
                            borderBottomStyle: 'solid',
                        },
                    },
                    '& .MuiSelect-select': {
                        paddingTop: 25,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingBottom: 8,
                    },
                }),
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    borderRadius: '4px 4px 0 0',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderWidth: 1,
                    borderStyle: 'solid',
                },
                standardSuccess: {
                    borderColor: alpha(baseTheme.palette.success.main, 0.4),
                },
                standardError: {
                    borderColor: alpha(baseTheme.palette.error.main, 0.4),
                },
                standardWarning: {
                    borderColor: alpha(baseTheme.palette.warning[600], 0.4),
                },
                standardInfo: {
                    borderColor: alpha(baseTheme.palette.blue[200], 0.4),
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '& a': {
                        color: baseTheme.palette.primary.main,
                        textDecoration: 'none',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    },
                },
            },
        },
    };

    return baseTheme;
};

export default overrideTheme;
